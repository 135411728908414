<template>
    <div class="w-100">
        <div class="d-flex w-100">
            <div class="flex-fill">
                <h5 class="mt-2 text-primary"><strong>Revenue by country {{(revenueRegionChart.viewAllTime ? "" : `last ${numberOfDateView} days`)}}</strong></h5>
            </div>
            <div>
                <strong class="text-muted mr-2">View all the time &nbsp;</strong>
                <el-switch v-model="revenueRegionChart.viewAllTime" @change="getRevenueByCountry"></el-switch>
            </div>
        </div>
        <highcharts v-if="tabIndex === 4" class="mt-4 mr-1" :options="revenueRegionChart.options"></highcharts>
        <highcharts v-if="tabIndex === 4" class="mt-4 mr-1" :options="revenueRegionCircleChart.options"></highcharts>
        <hr />
        <div class="d-flex w-100">
            <div class="flex-fill">
                <h5 class="mt-2 text-primary"><strong>Revenue by country {{(revenueRegionChart.viewAllTime ? "" : `last ${numberOfDateView} days`)}}</strong></h5>
            </div>
            <div>
                <strong class="text-muted mr-2">View all the time &nbsp;</strong>
                <el-switch v-model="revenueRegionChart.viewAllTime"></el-switch>
            </div>
        </div>
        <div class="mr-1 ml-1">
            <div class="row">
                <div :key="element.region"
                     v-for="element in revenueRegionChart.tableData"
                     class="col-6 col-md-2 m-0 p-1">
                    <div class="card mb-0">
                        <div class="text-left p-1 pr-3">
                            <div class="d-flex align-items-center">
                                <div class="mr-2" v-if="countryList[element.region] || element.region === 'FPTSOFT'">
                                    <img :src="`/flag/${element.region}.svg`" style="width: 60px;" />
                                </div>
                                <div>
                                    <span class="mb-0 text-nowrap">
                                        <strong>{{(element.region === 'FPTSOFT' ? 'FPT SOFTWARE' : (countryList[element.region] ? countryList[element.region] : 'OTHERS'))}}({{element.countUser}} {{element.countUser > 1 ? 'users' : 'user'}})</strong>
                                    </span>
                                    <div>
                                        <span class="mb-0 mt-0">
                                            <i class="fas fa-plus-circle text-success"></i> <strong>${{element.revenue | numFormat('0,0[.][0000]')}}</strong>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="modalDetailRegionRevenue" 
             tabindex="-1" role="dialog" 
             aria-labelledby="DetailRegionRevenue" aria-hidden="true">
            <div class="modal-dialog modal-lg-custom modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header" v-if="detailRRMData">
                        <h5 class="modal-title" id="modalDetailRegionRevenueTitle" v-html="detailRRMData.title"></h5>
                        <button type="button" class="close" @click="closeModalDetail()" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" v-if="detailRRMData" v-loading="isDetailLoading">
                        <div class="mb-2 d-flex">
                            <div>
                                <el-select v-model="detailRRMData.countryCode" placeholder="Select"
                                           size="small" @change="regionChange" filterable >
                                    <el-option v-for="item in countryListFilter"
                                               :key="item.countryCode"
                                               :label="item.countryName"
                                               :value="item.countryCode">
                                        <div class="d-flex align-items-center">
                                            <img :src="`/flag/${item.countryCode}.svg`" style="width: 25px; height: 16px; margin-right: 2px;" />&nbsp;
                                            <strong class="text-primary">{{(item.countryCode === 'FPTSOFT' ? 'FPT SOFTWARE' : (countryList[item.countryCode] ? countryList[item.countryCode] : "N/A"))}}</strong>
                                        </div>
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="ml-2">
                                <el-date-picker v-model="filterItem.timeFilter"
                                                size="small"
                                                :format="$elDatePickerFormat"
                                                type="daterange"
                                                :value-format="`yyyy-MM-dd`"
                                                range-separator="To"
                                                start-placeholder="Start date ..."
                                                end-placeholder="End date ..."></el-date-picker>
                            </div>
                            <div class="ml-2">
                                <el-radio-group v-model="filterItem.timeCycle"
                                                @change="timeCycleChanged"
                                                size="small">
                                    <el-radio-button label="daily">
                                        <i class="el-icon-check" v-if="filterItem.timeCycle === 'daily'"></i> Daily
                                    </el-radio-button>
                                    <el-radio-button label="monthly">
                                        <i class="el-icon-check" v-if="filterItem.timeCycle === 'monthly'"></i> Monthly
                                    </el-radio-button>
                                </el-radio-group>
                            </div>
                            <div class="ml-4 flex-fill" v-if="filterItem.timeCycle === 'daily'">
                                <el-button size="small" type="primary" plain @click="lastNDay(10)">Last 10 days</el-button>
                                <el-button size="small" type="primary" plain @click="lastNDay(20)">Last 20 days</el-button>
                                <el-button size="small" type="primary" plain @click="lastNDay(30)">Last 30 days</el-button>
                                <el-button size="small" type="primary" plain @click="lastNDay(40)">Last 40 days</el-button>
                            </div>
                            <div class="ml-4 flex-fill" v-if="filterItem.timeCycle === 'monthly'">
                                <el-button size="small" type="primary" plain @click="lastNMonth(6)">Last 6 months</el-button>
                                <el-button size="small" type="primary" plain @click="lastNMonth(12)">Last 12 months</el-button>
                                <el-button size="small" type="primary" plain @click="lastNMonth(18)">Last 18 months</el-button>
                                <el-button size="small" type="primary" plain @click="lastNMonth(24)">Last 24 months</el-button>
                            </div>
                            <div>
                                <el-button size="small" type="primary" @click="reset()"><i class="el-icon-refresh"></i> Refresh</el-button>
                            </div>
                        </div>
                        <div class="mb-2">
                            <div class="text-center">
                                <h4><strong>{{timerangeSubTitle}}</strong></h4>
                            </div>
                            <div>
                                <highcharts class="mt-2 mb-2" :options="revenueTimelineByRegion"></highcharts>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import Highcharts from 'highcharts';
    import chartDataApi from '@/api/common/chartData';
    import { barChartHoz, circleChart, areaSplineChart } from "@/constant/dashboardChartOptions";
    import { Chart } from 'highcharts-vue';
    import countryList from "@/constant/countryList";
    var numeral = require('numeral');
    import moment from 'moment';
    var instance = null;
    export default {
        components: {
            highcharts: Chart
        },
        props: {
            tabIndex: {
                type: Number,
                default: 0
            },
            numberOfDateView: {
                type: Number,
                default: 30
            }
        },
        data() {
            return {
                isLoadingChart: false,
                detailRRMData: null,
                timerangeSubTitle:  '',
                filterItem: {
                    timeFilter: [],
                    timeCycle: 'monthly',
                },
                revenueRegionChart: {
                    viewAllTime: true,
                    options: Object.assign(JSON.parse(JSON.stringify(barChartHoz)), {
                        xAxis: {
                            categories: [],
                            title: {
                                text: null
                            },
                            labels: {
                                events: {
                                    dblclick: function () {
                                        console.log('dbclick on xAxis label');
                                    },
                                    click: function () {
                                        console.log('click on xAxis label');
                                    },
                                    contextmenu: function () {
                                        console.log('context menu on xAxis label');
                                    }
                                },
                                useHTML: true,
                                animate: true,
                                formatter: function () {
                                    let value = this.value;
                                    return `<a role="button" class="openModalDetail-${value.region}">
                                                <img src="/flag/${value.region}.svg" style="width: 15px; height: 15px; margin-right: 5px;"/>&nbsp;
                                                <span style="display: inline-block; width: 135px;">${(value.region === 'FPTSOFT' ? 'FPT SOFTWARE' : (countryList[value.region] ? countryList[value.region] : "N/A"))} (${value.countUser})
                                                </span>
                                            </a>`;
                                }
                            }
                        },
                        tooltip: {
                            formatter: function () {
                                let value = this.x;
                                if (value) {
                                    return '<b>' + (value.region === 'FPTSOFT' ? 'FPT SOFTWARE' : (countryList[value.region] ? countryList[value.region] : "N/A")) +
                                        '</b><br/><b>$' + numeral(this.y).format('0,0.[0000]') + '</b>';
                                }
                                else return '';
                            },
                            valuePrefix: '$'
                        },
                        plotOptions: {
                            series: {
                                cursor: 'pointer',
                                point: {
                                    events: {
                                        click: function () {
                                            let category = this.category;
                                            if (category) {
                                                instance.openModalDetail(category.region);
                                            }
                                        }
                                    }
                                }
                            }
                        },
                    }),
                    tableData: [],
                },
                revenueRegionCircleChart: {
                    options: Object.assign(circleChart,
                        {
                            tooltip: {
                                formatter: function () {
                                    var value = this.value;
                                    return 'The revenue for <b>' + (value.region === 'FPTSOFT' ? 'FPT SOFTWARE' : (this.countryList[value.region] ? this.countryList[value.region] : "N/A")) +
                                        '</b> is <b>' + numeral(this.y).format('0,0.[0000]') + '$</b>';
                                },
                                valuePrefix: '$'
                            },
                            plotOptions: {
                                pie: {
                                    allowPointSelect: true,
                                    cursor: 'pointer',
                                    dataLabels: {
                                        enabled: true,
                                        format: '<b>{point.name}</b>: {point.percentage:.2f} %',
                                    },
                                    showInLegend: true
                                }
                            },
                            series: [{
                                name: 'Recharge($)',
                                data: []
                            }]
                        }),
                },
                revenueTimelineByRegion: Object.assign(JSON.parse(JSON.stringify(areaSplineChart)), {
                    chart: {
                        type: 'areaspline',
                        height: 500,
                    },
                    legend: { enabled: false },
                    xAxis: {
                        categories: [],
                        title: {
                            text: '',
                        },
                        allowDecimals: false,
                        tickInterval: 1,
                        labels: {
                            formatter: function () {
                                return this.value;
                            }
                        }
                    },
                    plotOptions: {
                        areaspline: {
                            dataLabels: {
                                enabled: true
                            },
                            fillColor: {
                                linearGradient: {
                                    x1: 0,
                                    y1: 0,
                                    x2: 0,
                                    y2: 1
                                },
                                stops: [
                                    [0, Highcharts.getOptions().colors[3]],
                                    [1, Highcharts.Color(Highcharts.getOptions().colors[8]).setOpacity(0.2).get('rgba')]
                                ]
                            },
                            marker: {
                                fillColor: Highcharts.getOptions().colors[1],
                                lineColor: Highcharts.getOptions().colors[1],
                                enabled: true
                            },
                            lineColor: '#303030'
                        },
                        series: {
                            tooltip: {
                                headerFormat: '{point.x}<br>',
                                pointFormat: '<b>${point.y}</b>',
                                clusterFormat: 'Clustered points: {point.clusterPointsAmount}'
                            },
                        },
                    },
                    series: [{
                        name: 'Revenue',
                        data: []
                    }]
                }),
                isDetailLoading: false,
                countryList: countryList
            };
        },
        computed: {
            countryListFilter() {
                return Object.keys(this.countryList).map(x => {
                    return {
                        countryCode: x,
                        countryName: this.countryList[x]
                    }
                });
            }
        },
        created() {
            window.openModalDetail = this.openModalDetail.bind(this);
        },
        mounted() {
            instance = this;
            this.getRevenueByCountry();
        },
        methods: {
            getRevenueByCountry() {
                chartDataApi.getRevenueByCountry(this.revenueRegionChart.viewAllTime ? 0 : this.numberOfDateView).then(response => {
                    if (response.data && response.data.result === 0 && response.data.data !== null) {
                        this.revenueRegionChart.tableData = response.data.data;
                        this.revenueRegionChart.options.xAxis.categories = [];
                        this.revenueRegionChart.options.xAxis.categories = response.data.data
                            //.filter(x => x.region !== "VN")
                            .map(x => x);
                        this.revenueRegionChart.options.series[0].data = response.data.data
                            //.filter(x => x.region !== "VN")
                            .map(x => (x.revenue));

                        let sumRevenue = response.data.data.reduce((a, b) => a + b.revenue, 0);
                        this.revenueRegionCircleChart.options.series[0].data = response.data.data
                            //.filter(x => x.region !== "VN")
                            .map(x => ({ name: `${(x.region === 'FPTSOFT' ? 'FPT SOFTWARE' : (this.countryList[x.region] ? countryList[x.region] : 'OTHERS'))} ${x.region} (${(Math.round(((x.revenue / sumRevenue * 100) + Number.EPSILON) * 100) / 100)}%)`, y: x.revenue }));
                        
                        setTimeout(() => {
                            this.revenueRegionChart.options.chart = {
                                height: response.data.data.length * 24,
                                type: 'bar'
                            };

                            this.revenueRegionCircleChart.options.chart = {
                                plotBackgroundColor: null,
                                plotBorderWidth: null,
                                plotShadow: false,
                                height: 800,
                                type: 'pie'
                            };
                        }, 1000);
                    }
                    else {
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            console.error(response.data.message || this.$lang.common.error);
                        }
                    }
                }).catch(error => {
                    console.error(error);
                });
            },
            getRevenueDetailOfRegion() {
                if (this.filterItem.timeFilter.length > 1 && this.detailRRMData) {
                    this.isDetailLoading = true;
                    chartDataApi.getRevenueDetailOfRegion({
                        startDate: this.filterItem.timeFilter[0],
                        endDate: this.filterItem.timeFilter[1],
                        timeCycle: this.filterItem.timeCycle,
                        countryCode: this.detailRRMData.countryCode
                    }).then(response => {
                        if (response.data && response.data.result === 0 && response.data.data !== null) {
                            this.revenueTimelineByRegion.xAxis.categories = [];
                            this.revenueTimelineByRegion.xAxis.categories = response.data.data
                                .map(x => x.xAxisRevenue);
                            this.revenueTimelineByRegion.series[0].data = response.data.data
                                .map(x => (x.TotalRechageAmount));

                            console.log('revenueTimelineByRegion categories', this.revenueTimelineByRegion.xAxis.categories);
                            console.log('revenueTimelineByRegion series', this.revenueTimelineByRegion.series);
                            this.isDetailLoading = false;
                        }
                        else {
                            if (response.data && response.data.message !== null && response.data.message !== '') {
                                console.error(response.data.message || this.$lang.common.error);
                            }
                            this.isDetailLoading = false;
                        }
                    }).catch(error => {
                        console.error(error);
                        this.isDetailLoading = false;
                    });
                }
            },
            timeCycleChanged() {
                if (this.filterItem.timeCycle) {
                    if (this.filterItem.timeCycle === 'daily') this.lastNDay(30);
                    else this.lastNMonth(6);
                }
            },
            openModalDetail(regionCode) {
                this.$root.globalLoading = true;
                this.isDetailLoading = true;
                setTimeout(() => {
                    let regionNameDisplay = (regionCode === 'FPTSOFT' ? 'FPT SOFTWARE' : (this.countryList[regionCode] ? this.countryList[regionCode] : "N/A"));
                    this.$set(this, 'detailRRMData', {
                        title: `<img src="/flag/${regionCode}.svg" style="width: 30px; height: 20px; margin-right: 2px;"/>&nbsp;
                            <strong class="text-primary">${regionNameDisplay}</strong>`,
                        countryCode: regionCode
                    });
                    this.$nextTick(() => {
                        this.lastNMonth(6);
                        $("#modalDetailRegionRevenue").modal({
                            backdrop: 'static',
                            keyboard: false
                        });
                        this.$root.globalLoading = false;
                    });
                }, 500);
            },
            closeModalDetail() {
                $("#modalDetailRegionRevenue").modal("hide");
                this.$set(this, 'detailRRMData', null);
            },
            regionChange() {
                let regionCode = this.detailRRMData.countryCode;
                let regionNameDisplay = (regionCode === 'FPTSOFT' ? 'FPT SOFTWARE' : (this.countryList[regionCode] ? this.countryList[regionCode] : "N/A"));
                let newTitle = `<img src="/flag/${regionCode}.svg" style="width: 30px; height: 20px; margin-right: 2px;"/>&nbsp;
                                <strong class="text-primary">${regionNameDisplay}</strong>`;
                this.$set(this.detailRRMData, 'title', newTitle);
                this.reset();
            },
            reset() {
                if (!this.filterItem) return;
                this.filterItem.timeCycle === 'monthly';
                this.lastNMonth(6);
                this.getRevenueDetailOfRegion();
            },

            lastNDay(numberOfLast) {
                if (!this.filterItem) return;
                this.filterItem.timeFilter = [moment().subtract(numberOfLast, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
                this.timerangeSubTitle = `Last ${numberOfLast} days, ${this.filterItem.timeFilter[0]} to ${this.filterItem.timeFilter[1]}`;
                this.getRevenueDetailOfRegion();
            },

            thisMonth() {
                if (!this.filterItem) return;
                this.filterItem.timeFilter = [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
                this.timerangeSubTitle = `This month, ${this.filterItem.timeFilter[0]} to ${this.filterItem.timeFilter[1]}`;
                this.getRevenueDetailOfRegion();
            },
            lastMonth() {
                if (!this.filterItem) return;
                this.filterItem.timeFilter = [moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')];
                this.timerangeSubTitle = `Last month, ${this.filterItem.timeFilter[0]} to ${this.filterItem.timeFilter[1]}`;
                this.getRevenueDetailOfRegion();
            },
            lastNMonth(numberOfLast = 1) {
                if (!this.filterItem) return;
                this.filterItem.timeFilter = [moment().subtract(numberOfLast, 'months').startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
                this.timerangeSubTitle = `Last ${numberOfLast} month, ${this.filterItem.timeFilter[0]} to ${this.filterItem.timeFilter[1]}`;
                this.getRevenueDetailOfRegion();
            },


            thisWeek() {
                if (!this.filterItem) return;
                this.filterItem.timeFilter = [moment().startOf('weeks').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
                this.timerangeSubTitle = `This week, ${this.filterItem.timeFilter[0]} to ${this.filterItem.timeFilter[1]}`;
                this.getRevenueDetailOfRegion();
            },
            lastWeek() {
                if (!this.filterItem) return;
                this.filterItem.timeFilter = [moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'), moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')];
                this.timerangeSubTitle = `Last week, ${this.filterItem.timeFilter[0]} to ${this.filterItem.timeFilter[1]}`;
                this.getRevenueDetailOfRegion();
            },
            lastNWeek(numberOfLast = 1) {
                if (!this.filterItem) return;
                this.filterItem.timeFilter = [moment().subtract(numberOfLast, 'weeks').startOf('week').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
                this.timerangeSubTitle = `Last ${numberOfLast} week, ${this.filterItem.timeFilter[0]} to ${this.filterItem.timeFilter[1]}`;
                this.getRevenueDetailOfRegion();
            },
            customTime() {

            }
        }
    }
</script>